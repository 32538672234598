$.fn.daterangepicker.defaultOptions = {
    locale: {
        'format': 'DD/MM/YYYY',
        'separator': ' - ',
        'applyLabel': '<i class="fa-solid fa-check"></i>',
        'cancelLabel': '<i class="fa-solid fa-xmark"></i>',
        'fromLabel': '<i class="fa-solid fa-right"></i>',
        'toLabel': '<i class="fa-solid fa-left"></i>',
        'customRangeLabel': '<i class="fa-solid fa-left-right"></i>',
        'weekLabel': 'W',
        'firstDay': 0
    }
};

$.fn.select2.defaults.set('theme', 'bootstrap-5');
$.fn.select2.defaults.set('width', null);
$.fn.select2.defaults.set('minimumResultsForSearch', Infinity);

$(document).ready(function() {
    $('form').on('submit', function(e) {
        $(this).find('button[type="submit"]').prepend('<i class="fa-solid fa-spinner"></i>').attr('disabled', 'true');
    });

    $('textarea').on('input', function() {
        this.style.height = '';
        this.style.height = (this.scrollHeight + 5) + 'px';
    });

    $(document).on('change', '.file-input', function() {
        let filesCount = $(this)[0].files.length;
        let textBox = $(this).prev();

        if (filesCount === 1) {
            let fileName = $(this).val().split('\\').pop();
            textBox.text(fileName);
        } else {
            textBox.text(filesCount + ' files selected');
        }
    });

    $('#optional_details').on('click', function() {
        if ($(this).is(':checked')) {
            $('#optional_details_holder').removeClass('d-none');
        } else {
            $('#optional_details_holder').addClass('d-none');
        }
    });

    const elements = document.querySelectorAll('.google-autocomplete');
    Array.from(elements).forEach((element, index) => {
        let name = $(element).attr('name');

        $(element).on('focus', function() {
            $(this).parent().find('.current-location').removeClass('d-none');
        }).on('blur', function() {
            setTimeout(() => {
                $(element).parent().find('.current-location').addClass('d-none');
            }, 500);
        }).on('keyup', function() {
            $('input[name="' + name + '_latitude"]').val('');
            $('input[name="' + name + '_longitude"]').val('');
            $('input[name="' + name + '_city"]').val('');
            $('input[name="' + name + '_real_latitude"]').val('');
            $('input[name="' + name + '_real_longitude"]').val('');
        });

        let autocomplete = new google.maps.places.Autocomplete(element);

        autocomplete.addListener('place_changed', function() {
            $(element).parent().find('.current-location').addClass('d-none');
            let place = autocomplete.getPlace();

            let cityAddress = '';
            let added = [];

            place.address_components.forEach(item => {
                if (item.types.includes('locality') || item.types.includes('postal_town')) {
                    cityAddress += item.long_name;
                    added.push(item.long_name);
                }

                if (item.types.includes('administrative_area_level_1') && !added.includes(item.long_name)) {
                    cityAddress += cityAddress === '' ? item.long_name : ', ' + item.long_name;
                    added.push(item.long_name);
                }

                if (item.types.includes('country') && !added.includes(item.long_name)) {
                    cityAddress += cityAddress === '' ? item.long_name : ', ' + item.long_name;
                }
            });

            const geocoder = new google.maps.Geocoder;
            geocoder.geocode({'address': cityAddress}, function(results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    $('input[name="' + name + '_latitude"]').val(results[0].geometry.location.lat());
                    $('input[name="' + name + '_longitude"]').val(results[0].geometry.location.lng());
                    $('input[name="' + name + '_city"]').val(cityAddress);
                }
            });

            $('input[name="' + name + '_real_latitude"]').val(place.geometry['location'].lat());
            $('input[name="' + name + '_real_longitude"]').val(place.geometry['location'].lng());
        });
    });

    $('.deadline-date-btn').on('click', function() {
        let input = $('input[name="timeframe"]:visible');

        $(input).val($(this).data('text'));
        $(input).parent().find('input[name="timeframe_value"]').val($(this).data('value'));

        closeDeliveryTimeframeModal();
    });

    $('#choose_specific_date').daterangepicker({
        singleDatePicker: true, minDate: moment()
    }, function(start, end, label) {
        let date = start.format('DD/MM/YYYY');

        let input = $('input[name="timeframe"]:visible');

        $(input).val(date);
        $(input).parent().find('input[name="timeframe_value"]').val(3);

        closeDeliveryTimeframeModal();
    });

    $('#choose_between_dates').daterangepicker({
        minDate: moment()
    }, function(start, end, label) {
        let dateStart = start.format('DD/MM/YYYY');
        let dateEnd = end.format('DD/MM/YYYY');

        let input = $('input[name="timeframe"]:visible');

        $(input).val(dateStart + ' - ' + dateEnd);
        $(input).parent().find('input[name="timeframe_value"]').val(4);

        closeDeliveryTimeframeModal();
    });

    $('.fa-select').select2({
        templateSelection: formatText, templateResult: formatText
    });

    if (document.getElementById('map')) {
        quoteMap();
    }

    if (typeof reportErrorModal !== 'undefined') {
        openReportModal();
    }

    const quoteImageModalElem = document.getElementById('quoteImagesModal');
    if (quoteImageModalElem) {
        quoteImageModalElem.addEventListener('show.bs.modal', function(event) {
            let key = $(event.relatedTarget).data('key');
            $(this).find('button[data-image-button]').removeClass('active').removeAttr('aria-current');
            $(this).find('button[data-image-button="' + key + '"]').addClass('active').attr('aria-current', 'true');
            $(this).find('div[data-image]').removeClass('active');
            $(this).find('div[data-image="' + key + '"]').addClass('active');
        });
    }

    const rejectQuoteElem = document.getElementById('rejectQuoteModal');
    if (rejectQuoteElem) {
        rejectQuoteElem.addEventListener('show.bs.modal', function(event) {
            let key = $(event.relatedTarget).data('key');
            $(this).find('#rejectBidId').val(key);
        });
    }

    const acceptQuoteElem = document.getElementById('acceptQuoteModal');
    if (acceptQuoteElem) {
        acceptQuoteElem.addEventListener('show.bs.modal', function(event) {
            let url = $(event.relatedTarget).data('url');
            let bidId = $(event.relatedTarget).data('key');

            let that = this;

            $.ajax({
                type: 'POST',
                url: url,
                data: {
                    bidId
                },
                success: function(data) {
                    $(that).find('.modal-body').html(data);
                }
            });
        });

        acceptQuoteElem.addEventListener('hidden.bs.modal', function(event) {
            $(this).find('.modal-body').html(
                '<div class="flex justify-content-center mt-5 mb-5"><div class="spinner-border text-primary custom-spinner" role="status"><span class="sr-only"></span></div></div>');
        });
    }

    const reportMessageElem = document.getElementById('reportMessageModal');
    if (reportMessageElem) {
        reportMessageElem.addEventListener('show.bs.modal', function(event) {
            let key = $(event.relatedTarget).data('key');
            let message = $(event.relatedTarget).data('message');
            $(this).find('#reportBidId').val(key);
            $(this).find('#reportMessageId').val(message);
        });
    }

    $('.delete-image').on('click', function() {
        $(this).closest('.current_image').remove();
    });

});

quoteMap = function() {
    let start = new google.maps.LatLng(startLat, startLng);
    let end = new google.maps.LatLng(endLat, endLng);

    let directionsService = new google.maps.DirectionsService();
    let directionsDisplay = new google.maps.DirectionsRenderer({suppressMarkers: true});
    let map = new google.maps.Map(document.getElementById('map'));
    directionsDisplay.setMap(map);

    let bounds = new google.maps.LatLngBounds();
    bounds.extend(start);
    bounds.extend(end);
    map.fitBounds(bounds);

    let request = {
        origin: start, destination: end, travelMode: google.maps.TravelMode.DRIVING
    };

    directionsService.route(request, function(response, status) {
        if (status === google.maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(response);
            directionsDisplay.setMap(map);
            let leg = response.routes[0].legs[0];

            $('#routeDistance').text(leg.distance.text).parent().removeClass('d-none');
            $('#routeTime').text(leg.duration.text).parent().removeClass('d-none');

            new google.maps.Marker({
                position: leg.start_location, map: map, icon: {
                    path: 'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z',
                    anchor: new google.maps.Point(12, 17),
                    fillOpacity: 1,
                    fillColor: '#5cb85c',
                    strokeWeight: 1,
                    strokeColor: '#5cb85c',
                    scale: 1.5,
                    labelOrigin: new google.maps.Point(12, 15)
                }
            });

            new google.maps.Marker({
                position: leg.end_location, map: map, icon: {
                    path: 'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z',
                    anchor: new google.maps.Point(12, 17),
                    fillOpacity: 1,
                    fillColor: '#d9534f',
                    strokeWeight: 1,
                    strokeColor: '#d9534f',
                    scale: 1.5,
                    labelOrigin: new google.maps.Point(12, 15)
                }
            });
        } else {
            console.error('Map directions failed');
        }
    });
};

getCurrentLocation = function(el, name) {
    $(el).addClass('d-none');

    let options = {
        enableHighAccuracy: true, timeout: 5000, maximumAge: 10000
    };
    navigator.geolocation.getCurrentPosition(pos => {
        const latLng = {
            lat: pos.coords.latitude, lng: pos.coords.longitude
        };

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({location: latLng}, (results, status) => {
            if (status === 'OK' && results[0]) {
                let cityAddress = '';
                let added = [];

                results[0].address_components.forEach(item => {
                    if (item.types.includes('locality') || item.types.includes('postal_town')) {
                        cityAddress += item.long_name;
                        added.push(item.long_name);
                    }

                    if (item.types.includes('administrative_area_level_1') && !added.includes(item.long_name)) {
                        cityAddress += cityAddress === '' ? item.long_name : ', ' + item.long_name;
                        added.push(item.long_name);
                    }

                    if (item.types.includes('country') && !added.includes(item.long_name)) {
                        cityAddress += cityAddress === '' ? item.long_name : ', ' + item.long_name;
                    }
                });

                const currentGeocoder = new google.maps.Geocoder;
                currentGeocoder.geocode({'address': cityAddress}, function(currentResults, currentStatus) {
                    if (currentStatus === google.maps.GeocoderStatus.OK) {
                        $('input[name="' + name + '_latitude"]').val(currentResults[0].geometry.location.lat());
                        $('input[name="' + name + '_longitude"]').val(currentResults[0].geometry.location.lng());
                        $('input[name="' + name + '_city"]').val(cityAddress);
                    }
                });

                let formattedAddress = results[0].formatted_address;
                $('input[name="' + name).val(formattedAddress);
                $('input[name="' + name + '_real_latitude"]').val(pos.coords.latitude);
                $('input[name="' + name + '_real_longitude"]').val(pos.coords.longitude);
            }
        });
    }, e => {

    }, options);
};

setRegisterType = function(el, type) {
    $('#registerType').val(type);

    $('.btn-register-type').removeClass('register-type-selected');
    $(el).addClass('register-type-selected');

    $('#registerForm').removeClass('d-none');

    if (type === 'transporter') {
        $('.for-customer').addClass('d-none');
        $('.for-transporter').removeClass('d-none');
    } else {
        $('.for-transporter').addClass('d-none');
        $('.for-customer').removeClass('d-none');
    }
};

closeDeliveryTimeframeModal = () => {
    const element = document.getElementById('deliveryTimeframeModal');
    const modal = bootstrap.Modal.getOrCreateInstance(element);
    modal.hide();
};

openReportModal = () => {
    const element = document.getElementById('reportQuoteModal');
    const modal = bootstrap.Modal.getOrCreateInstance(element);
    modal.show();
};

toggleFilters = () => {
    if ($('#searchFilters').hasClass('d-none')) {
        $('#searchFilters').removeClass('d-none');
    } else {
        $('#searchFilters').addClass('d-none');
    }
};

formatText = (icon) => {
    if ($(icon.element).data('text') !== undefined) {
        return $('<span><i class="fas ' + $(icon.element).data('icon') + '"></i> ' + $(icon.element).data('text') + '</span>');
    } else {
        return $('<span>&nbsp;</span>');
    }
};

loadStripeIntent = (clientSecret) => {
    stripeElements = stripe.elements({clientSecret});

    let paymentElement = stripeElements.create('payment');
    paymentElement.mount('#payment-element');
};

processStripePayment = (e) => {
    e.preventDefault();

    $('#payment-error').html('').addClass('d-none');
    $('#payment-submit').prepend('<i class="fa-solid fa-spinner"></i>').attr('disabled', 'true');

    let url = $(e.target).attr('action');

    stripe.confirmPayment({
        elements: stripeElements,
        redirect: 'if_required'
    }).then(function(response) {
        if (response.error) {
            $('#payment-error').html(response.error.message).removeClass('d-none');
            $('#payment-submit').removeAttr('disabled').find('i').remove();
        } else {
            if (response.paymentIntent && response.paymentIntent.status === 'succeeded') {
                $.ajax({
                    type: 'POST',
                    url: url,
                    data: {...response},
                    success: function() {
                        $('#payment-info').removeClass('d-none');

                        setTimeout(function() {
                            window.location.reload();
                        }, 5000);
                    }
                });
            }
        }
    });
};

markBidMessagesAsRead = (url) => {
    $.ajax({
        type: 'POST',
        url: url,
        data: {
            read: true
        },
        success: function() {
        }
    });
};